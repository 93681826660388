<template>
  <div id="user-view">
    <v-row v-if="author && author.id">
      <v-col
        cols="12"
      >
        <author-bio-panel
          :author="author"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
        ></author-bio-panel>
      </v-col>

      <v-col
        cols="12"
      >
        <v-tabs
          v-model="authorTab"
          show-arrows
          class="user-tabs"
          grow
        >
          <v-tab
            value="manuscripts"
            href="#manuscripts"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiBookAccountOutline }}
            </v-icon>
            <span>{{ $t("Manuscrits") }}</span>
          </v-tab>
          <v-tab
            value="publications"
            href="#publications"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiBookMultipleOutline }}
            </v-icon>
            <span>{{ $t("Publications") }}</span>
          </v-tab>

          <v-tab
            value="addresses"
            href="#addresses"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiCity }}
            </v-icon>
            <span>{{ $t("Adresses") }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="authorTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            key="publications"
            value="publications"
          >
            <!--            <author-tab-overview></author-tab-overview>-->
            <!--            <activities />-->
            <Publications />
          </v-tab-item>
          <v-tab-item
            key="manuscripts"
            value="manuscripts"
          >
            <!--            <author-tab-overview></author-tab-overview>-->
            <!--            <activities />-->
            <Manuscripts />
          </v-tab-item>
          <v-tab-item
            key="addresses"
            value="addresses"
          >
            <!--            <author-tab-overview></author-tab-overview>-->
            <!--            <activities />-->
            <addresses
              v-model="author"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiLockOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLinkVariant,
  mdiAccountCogOutline,
  mdiBookAccountOutline,
  mdiBookMultipleOutline,
  mdiCity,
} from '@mdi/js'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import AuthorBioPanel from './author-bio-panel/AuthorBioPanel.vue'
import authorStoreModule from '../authorStoreModule'
import AuthorTabOverview from './author-tab-overview/AuthorTabOverview.vue'
import AuthorTabSecurity from './author-tab-security/AuthorTabSecurity.vue'

import AccountSettingsAccount from '@/views/pages/account-settings/AccountSettingsAccount.vue'
import Activities from '@/views/apps/author/author-view/author-tab-overview/Activities'
import KnowledgeBase from '@/views/pages/knowledge-base/KnowledgeBase'
import Addresses from '@/views/apps/author/author-list/Addresses'
import Manuscripts from '@/views/apps/manuscript/Manuscripts'
import Publications from '@/views/apps/publications/Publications'

export default {
  components: {
    Publications,
    Manuscripts,
    Addresses,
    KnowledgeBase,
    Activities,
    AccountSettingsAccount,
    AuthorBioPanel,
    AuthorTabOverview,
    AuthorTabSecurity,

    /* AuthorTabBillingsPlans,
    AuthorTabNotifications,
    AuthorTabConnections, */
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-author'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, authorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const author = ref({})
    const authorTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-author/fetchAuthor', { id: router.currentRoute.params.id })
      .then(response => {
        author.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          author.value = {}
        }
      })

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Historique des activités' },
      { icon: mdiAccountCogOutline, title: 'Paramètres' },
      { icon: mdiLockOutline, title: 'Sécurité' },

      /* { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' }, */
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      authorTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      author,
      AuthorTabOverview,
      resolveCurrentPlanValue,
      mdiAccountOutline,
      mdiAccountCogOutline,
      mdiLockOutline,
      mdiBookAccountOutline,
      mdiBookMultipleOutline,
      mdiCity,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
